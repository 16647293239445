
<template>
  <fieldsRows
    v-if="templateContent != null"
    :templateContent="templateContent"
    @field-value-changed="updateFieldValue(...arguments)"
    :valueLine="valueLine"
  ></fieldsRows>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
function getTestCaseOptions(caller, Category) {
  return new Promise((resolve) => {
    let options = {
      function: "getTestcaseListByCat",
      cat: [...Category],
    };

    caller.frameworkAxiosRequest({
      method: 'post',
      url: 'serve.php?f=testing&f2=configureAndRunTests',
      data: options,
    })
        .then(function (response) {
        //templateContent.fields.testcaseId.associatedOption =
        //   response.data.result.json.testcaseList;
        ////console.log(response.data.result.json.testcaseList);
        // caller.$set(
        //   templateContent.fields.testcaseId,
        //   "associatedOption",
        //   response.data.result.json.testcaseList
        // );
        resolve(response.data.result.json.testcaseList);
      })
      .catch(function () {
        //handle error
      });

    //    return templateContent;
  });
}
export default {
  components: { fieldsRows },
  data() {
    return {};
  },
  props: ["result", "stepStoredValue"],

  asyncComputed: {
    async testCaseOptions() {
      let caller = this;
     // console.log("getting testCaseOptions");
      return await getTestCaseOptions(caller, this.category);
    },
  },
  computed: {
    valueLine() {
      return {
        testCategory: this.category,
        testcaseId: this.stepStoredValue.testcaseId,
      };
    },
    templateContent() {
      let templateContent = {
        fields: {
          testCategory: {
            type: "combobox",
            name: "Category",
            associatedOption: this.result.additional.domainTestGroups,
            fieldAttrInput: {
              multiple: true,
              class: "required",
              chips: true,
            },
          },
          testcaseId: {
            type: "combobox",
            name: "Test",
            fieldAttrInput: {
              class: "required",
            },
          },
        },
      };

      // templateContent.fields.testCategory.value = this.category;
      // templateContent.fields.testcaseId.value = this.stepStoredValue.testcaseId;
      templateContent.fields.testcaseId.associatedOption = this.testCaseOptions;
      return templateContent;
    },
    category() {
      let category = [];
      if (
        typeof this.stepStoredValue.Filter != "undefined" &&
        typeof this.stepStoredValue.Filter.Category != "undefined"
      ) {
        category = this.stepStoredValue.Filter.Category;
      }
     // console.log("gettingCategory", category, this.stepStoredValue);
      return category;
    },
  },
  methods: {
    updateFieldValue(field, value, text) {
      //console.log(field, value, text, selectedValue);
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.Filter == "undefined") {
        this.$set(localStoredStep, "Filter", {});
      }
      if (field == "testCategory") {
        // localStoredStep.Filter.Category = value;
        this.$set(localStoredStep.Filter, "Category", value);
      }
      if (field == "testcaseId") {
        // localStoredStep.testcaseName = text;
        this.$set(localStoredStep, "testcaseName", text);
        this.$set(localStoredStep, field, value);
        //localStoredStep[field] = value;
      } else {
        //localStoredStep[field] = value;
        this.$set(localStoredStep, field, value);
      }
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>


